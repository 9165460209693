<template>
  <v-scale-transition>
    <kits-panel :kits-items="operationKits">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Operation Kits
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import('@/components/KitsPanel'),
  },
  data () {
    return {
      operationKits: [
        {
          icon: 'mdi-office-building',
          path: '/operation/corporate_kits',
          title: 'Corporate Kits',
          desc: 'Corporate activations & payments.',
        },
        {
          icon: 'mdi-family-tree',
          path: '/operation/transfer_hierarchy',
          title: 'Transfer Hierarchy',
          desc: 'Transfer hierarchy between dealers.',
        },
        {
          icon: 'mdi-cellphone-charging',
          path: '/operation/topup_staff',
          title: 'Staff Topup Benefit',
          desc: 'Monthly executed staff topup benefit.',
        },
        {
          icon: 'mdi-hand-coin-outline',
          path: '/operation/ifinancing',
          title: 'iFinancing',
          desc: 'iFinancing payments',
        },
      ],
    }
  },
}
</script>
